import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

/**
 * @description Transforms the input address by adding ellipsis between the first 5 and the last 4 characters.
 */
@Pipe({
  name: 'addressEllipsis',
  standalone: true,
})
export class AddressEllipsisPipe implements PipeTransform {
  /**
   * Transforms the input address by adding ellipsis between the first 5 and the last 4 characters.
   * If the address is undefined, null, or too short, it returns the address as is.
   *
   * @param address - The input address to be transformed.
   * @returns The shortened address with ellipsis in the middle, or the original address if it is too short.
   */
  public transform(address: string | undefined | null): string {
    if (address && address?.length > 4) {
      return `${address.slice(0, 5)}...${address.slice(-4, address.length)}`;
    }
    return address ?? '';
  }
}
