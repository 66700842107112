import type { BreakpointState } from '@angular/cdk/layout';
import { BreakpointObserver } from '@angular/cdk/layout';
import type { Signal } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { computed, Inject, Injectable, signal } from '@angular/core';
import type { ExtractPath, ExtractType } from '@dextools/utils';
import type { BreakpointsConfig } from '../models/breakpoints.model';
import { BREAKPOINTS_CONFIG } from '../models/breakpoints.model';
import type { DeviceConfig } from '../models/device.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly _deviceConfig = signal<DeviceConfig>({
    // TODO. See if orientation is needed
    // orientation: 'portrait',
    isTouchable: false,
    displayResolution: 'desktop',
    displayResolutionRange: ['<=desktop'],
  });

  public constructor(
    private readonly _breakpointObserver: BreakpointObserver,
    @Inject(BREAKPOINTS_CONFIG) private readonly _breakpoints: BreakpointsConfig,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
  ) {
    //noop
  }

  public initialize(): void {
    const breakpoints = [...Object.values(this._breakpoints)];

    this._breakpointObserver.observe(breakpoints).subscribe((result) => {
      this._detectDisplayResolution(result);
      this._detectTouchable();
    });
  }

  private _setDeviceConfig<SomeKey extends keyof DeviceConfig>(key: SomeKey, value: DeviceConfig[SomeKey]) {
    this._deviceConfig.update((config) => {
      const newConfig = { ...config };
      newConfig[key] = value;
      return newConfig;
    });
  }

  /**
   * Returns a reactive reference to a state's property.
   *
   * @param path - the path of the state property.
   *
   * @returns A reactive reference to the state's property.
   */
  public getDeviceConfig<SomePath extends ExtractPath<DeviceConfig>>(path: SomePath): Signal<ExtractType<DeviceConfig, SomePath>> {
    return computed(() => {
      const keys = path.split('.');
      // eslint-disable-next-line unicorn/no-array-reduce
      return keys.reduce((obj: any, key: string) => obj && obj[key], this._deviceConfig());
    });
  }

  private _detectTouchable() {
    this._setDeviceConfig(
      'isTouchable',
      isPlatformBrowser(this._platformId) ? 'ontouchstart' in window || navigator.maxTouchPoints > 0 : false,
    );
  }

  private _detectDisplayResolution({ breakpoints }: BreakpointState) {
    if (this._breakpoints.smallMobile && breakpoints[this._breakpoints.smallMobile]) {
      this._setDeviceConfig('displayResolution', 'smallMobile');
      this._setDeviceConfig('displayResolutionRange', ['>=smallMobile', '<=mobile', '<=tablet', '<=desktop']);
    } else if (breakpoints[this._breakpoints.mobile]) {
      this._setDeviceConfig('displayResolution', 'mobile');
      this._setDeviceConfig('displayResolutionRange', ['<=mobile', '>=mobile', '<=tablet', '<=desktop', '>=smallMobile']);
    } else if (breakpoints[this._breakpoints.tablet]) {
      this._setDeviceConfig('displayResolution', 'tablet');
      this._setDeviceConfig('displayResolutionRange', ['<=tablet', '>=tablet', '<=desktop', '>=smallMobile', '>=mobile']);
    } else {
      this._setDeviceConfig('displayResolution', 'desktop');
      this._setDeviceConfig('displayResolutionRange', ['>=desktop', '<=desktop', '>=smallMobile', '>=mobile', '>=tablet']);
    }
  }
}
