import { Injectable } from '@angular/core';
import { ClipboardUtil } from '@dextools/utils';
import type { TippyDirective } from '@ngneat/helipopper';
import { TranslateService } from '@ngx-translate/core';
import { delay, filter, take } from 'rxjs';

const TOOLTIP_TTL = 1_000;

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  public constructor(private readonly _translate: TranslateService) {
    // noop
  }

  public copyToClipboard(textToCopy: string, tooltip?: TippyDirective) {
    ClipboardUtil.copyToClipboard(textToCopy);
    if (tooltip) this._showSuccessfulCopiedTooltip(tooltip);
  }

  private _showSuccessfulCopiedTooltip(tooltip: TippyDirective) {
    const originalAnimation = tooltip.animation;
    const originalText = tooltip.content;

    tooltip.animation = false;
    tooltip.hide();
    tooltip.content = this._translate.instant('common.tooltips.copied');
    tooltip.show();

    tooltip.visible
      .pipe(
        filter((visible) => !!visible),
        take(1),
        delay(TOOLTIP_TTL),
      )
      .subscribe(() => {
        tooltip.hide();
        tooltip.animation = originalAnimation;
        tooltip.content = originalText;
      });
  }
}
