import { InjectionToken } from '@angular/core';

export interface BreakpointsConfig {
  smallMobile?: string;
  mobile: string;
  tablet: string;
  desktop: string;
}

export const BREAKPOINTS_CONFIG = new InjectionToken<BreakpointsConfig>('BreakpointsConfig');
