declare global {
  // App mobile only interfaces
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JSInterface: any; // Android
  }
}

export class ClipboardUtil {
  public static copyToClipboard(text: string) {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then();
      } else {
        ClipboardUtil.fallbackCopyToClipboard(text);
      }

      // Android needs a native method to copy text to the clipboard :S
      if (window.JSInterface != null) {
        window.JSInterface.copyClipboard(text);
      }
    } catch (error) {
      return console.warn(`Error: failed to copy text to clipboard. ${error}`);
    }
  }

  private static fallbackCopyToClipboard(text: string) {
    const dummy = document.createElement('textarea');
    // to avoid breaking original page when copying more words
    // can't copy when adding below this code
    // dummy.style.display = 'none'
    document.body.append(dummy);
    // Be careful when using textarea. setAttribute('value', value) works with "input" but not with "textarea"
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    dummy.remove();
  }
}
